import React, { PureComponent } from "react";
import PropTypes from "prop-types";

class IBox extends PureComponent {
    render() {
        return (
            <div className={"ibox " + (this.props.className || "")}>
                {(this.props.title || this.props.tools) && (
                    <div className="ibox-title">
                        <h3>{this.props.title}</h3>
                        {this.props.tools && <div className="ibox-tools">{this.props.tools}</div>}
                    </div>
                )}
                {this.props.header && <div className="ibox-title">{this.props.header}</div>}
                <div className="ibox-content">{this.props.children}</div>
            </div>
        );
    }
}

IBox.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    tools: PropTypes.object,
    header: PropTypes.object,
    children: PropTypes.any,
};

export default IBox;
